<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import axios from 'axios'

export default {
  components: {
    Layout,
    PageHeader,
  },
  props: {

  },
  data() {
    return {
      title: this.$t('서버 상태'),
      search: {

      },
      sort: {
        field: 'createdAt',
        directive: -1
      },
      list: [],
      limit: 1000,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
      flagStatus: null
    }
  },
  created() {

  },
  unmounted() {
    clearTimeout(this.flagStatus)
  },
  mounted() {
    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let directive
      if (field === this.sort.field) {
        directive = this.sort.directive === -1 ? 1 : -1
      } else {
        directive = -1
      }

      this.sort.field = field
      this.sort.directive = directive

      this.searchList().then()
    },
    searchByPage: function(page) {
      this.page = page
      this.searchList().then()
    },
    getServerStatus: async function() {
      const self = this
      try {
        for(const key in this.list) {
          const server = this.list[key]
          const result = await axios.get(server.url + '/?command=getStatus', {headers: {'admin-token': '$2b$12$ZWQGsGl0.k38pB6ScI43b.ascAzsxEDcpSEizueh6uqdz14BTa2Ay'}})
          if (result.data.code === 0) {
            server.data = result.data.data
          }
        }
      } catch(e) {
        this.$log.error(e)
      } finally {
        this.flagStatus = setTimeout(function() {
          self.getServerStatus().then()
        }, 10000)
      }
    },
    searchList: async function() {
      const loader = this.$loading.show()
      try {
        const params = {
          search: {},
          sort: {},
          limit: this.limit,
          page: this.page
        }
        params.sort[this.sort.field] = this.sort.directive

        this.list = []
        const result = await this.$API.setting.servers(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(const key in result.documents) {
          const server = result.documents[key]
          server.data = null
          this.list.push(server)
        }

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
        this.page = result.page

        this.getServerStatus().then()
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mb-0 rounded">
              <table class="table align-middle table-nowrap">
                <thead class="table-dark">
                <tr>
                  <th class="align-middle">Server</th>
                  <th class="align-middle">Load</th>
                  <th class="align-middle">Mem</th>
                  <th class="align-middle">배팅횟수(초당)</th>
                  <th class="align-middle">IO</th>
                  <th class="align-middle">NETWORK</th>
                  <th class="align-middle">Connections</th>
                </tr>
                </thead>
                <tbody>
                <tr class="bg-soft" v-for="(server) in list" :key="server._id">
                  <td>
                    <h6>{{ server.name }}</h6>
                    <div>{{ server.url }}</div>
                    <div class="small">{{ new Date(server.startedAt).format('YYYY-MM-DD HH:mm:ss.zzz')}}</div>
                    <div><span class="badge" :class="{'bg-primary':server.active === true, 'bg-danger':server.active !== true}">{{server.active === true ? 'ACTIVE' : 'INACTIVE'}}</span></div>
                  </td>
                  <td class="text-start">
                    <template v-if="server.data">
                      <div>avg: {{ server.data.load.avgLoad.floatVal(2) }}</div>
                      <div>current: {{ server.data.load.currentLoad.floatVal(2) }}%</div>
                    </template>
                  </td>
                  <td class="text-start">
                    <template v-if="server.data">
                      <div>total: {{ server.data.mem.total.filesize() }}</div>
                      <div>free: {{ server.data.mem.free.filesize() }}</div>
                      <div>used: {{ server.data.mem.used.filesize() }}</div>
                      <div>available: {{ server.data.mem.available.filesize() }}</div>
                    </template>
                  </td>
                  <td class="text-start">
                    <template v-if="server.data">
                      <div>BPS: {{ server.data.bps.formatThousands() }}</div>
                    </template>
                  </td>
                  <td class="text-start">
                    <template v-if="server.data">
                      <div>rIO: {{ server.data.io.rIO.formatThousands() }}</div>
                      <div>wIO: {{ server.data.io.wIO.formatThousands() }}</div>
                      <div>rIO_sec: {{ server.data.io.rIO_sec.floatVal(2).formatThousands() }}</div>
                      <div>wIO_sec: {{ server.data.io.wIO_sec.floatVal(2).formatThousands() }}</div>
                    </template>
                  </td>
                  <td class="text-start">
                    <div  v-if="server.data">
                      <template v-for="(network, index) in server.data.network" :key="'network-' + index">
                        <h6>{{ network.iface }}</h6>
                        <hr />
                        <div>rx_sec: {{ network.rx_sec.filesize() }}</div>
                        <div>tx_sec: {{ network.tx_sec.filesize() }}</div>
                      </template>
                    </div>
                  </td>
                  <td class="text-start">
                    <template v-if="server.data">
                      <div>total: {{ server.data.totalConnectionCount.formatThousands() }}</div>
                      <div>http: {{ server.data.httpConnectionCount.formatThousands() }}</div>
                      <div>mongo: {{ server.data.mongoDbConnectionCount.formatThousands() }}</div>
                      <div>mysql: {{ server.data.mysqlConnectionCount.formatThousands() }}</div>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
